@keyframes pulse {
    0% {
        color: white;
    }
    50% {
        color: grey;
    }
    100% {
        color: white;
    }
  }
  
  